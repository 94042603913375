import "normalize.css";
import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "@daangn/sprout-components-tabs/index.css";
import "@daangn/sprout-components-button/index.css";
import "@daangn/sprout-components-chips/index.css";
import "@daangn/sprout-components-text-field/index.css";
import "@daangn/sprout-components-spinner/index.css";
import "./src/styles/global.css";

import type { GatsbyBrowser } from "gatsby";
import React from "react";

import { Stack } from "~/stackflow";

import { ErrorBoundary } from "@sentry/gatsby";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  props: { location, data },
}) => (
  <React.StrictMode>
    <ErrorBoundary>
      <React.Suspense>
        <Stack initialContext={{ req: { path: location.pathname }, data }} />
      </React.Suspense>
    </ErrorBoundary>
  </React.StrictMode>
);
