import { IconWarningFill } from "@seed-design/icon";
import React from "react";

import * as css from "./ErrorBase.css";

export default function ErrorBase() {
  return (
    <div className={css.container}>
      <div className={css.icon}>
        <IconWarningFill width={60} height={60} />
      </div>
      <h1 className={css.title}>페이지를 찾을 수 없어요</h1>
      <p className={css.description}>
        링크가 잘못되었거나 페이지가 삭제되었을 수 있어요.
      </p>
    </div>
  );
}
