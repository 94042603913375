import { StackflowPlugin } from "@stackflow/core";
import { karrotBridge } from "~/bridge";

// let userInteracted = false;

// if (window) {
//   window.addEventListener(
//     "click",
//     () => {
//       userInteracted = true;
//     },
//     { once: true },
//   );
// }

const closeWhenRootPopPlugin = (): StackflowPlugin => {
  return () => ({
    key: "closeWhenRootPop",
    onBeforePop({ actions }) {
      const stack = actions.getStack();
      const activeActivity = stack.activities.find(
        (activity) => activity.isActive,
      );

      if (activeActivity?.isRoot) {
        actions.preventDefault();

        karrotBridge?.closeRouter({});
      }
    },
  });
};

export default closeWhenRootPopPlugin;
