import React from "react";

import * as css from "./Loading.css";
import { Spinner } from "@daangn/sprout-components-spinner";

export default function Loading() {
  return (
    <div className={css.container}>
      <Spinner />
    </div>
  );
}
