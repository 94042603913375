import type { KarrotBridgeSchema } from "@daangn/karrotbridge/lib/__generated__/makeKarrotBridge";
import { karrotBridge } from "./karrotBridge";

export const closeWebview = () => karrotBridge?.closeRouter({});

export const changeRouteStyle = (
  option: KarrotBridgeSchema["ReqRouterStyleRequestBody"]["router"],
) => {
  karrotBridge?.styleCurrentRouter({
    router: {
      navbar: false,
      scrollable: false,
      ...option,
    },
  });
};
