module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-df5c10f00b/2/.yarn/berry/cache/gatsby-plugin-google-gtag-npm-4.25.0-0abca1b5b1-10c0.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HYKJKNHCCN"]},
    },{
      plugin: require('../.yarn/__virtual__/@sentry-gatsby-virtual-1ed46cfe47/2/.yarn/berry/cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-10c0.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://37cde6a3f07f7be6abc03489f9272248@o24217.ingest.us.sentry.io/4506903974510592","environment":"production","normalizeDepth":6,"tracesSampleRate":0,"replaysSessionSampleRate":0,"replaysOnErrorSampleRate":1,"browserTracingOptions":{"traceXHR":false}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-7f5b3d36f7/2/.yarn/berry/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-31e4c744ce/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
