import { karrotAnalyticsPlugin } from "@daangn/stackflow-plugin-karrot-analytics";
import { vars } from "@seed-design/design-token";
import { captureMessage, Severity } from "@sentry/gatsby";
import { basicUIPlugin } from "@stackflow/plugin-basic-ui";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { preloadPlugin } from "@stackflow/plugin-preload";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { stackflow } from "@stackflow/react";
import * as React from "react";

// import { browserHistory } from "utils/history";
import { karrotBridge } from "~/bridge";

import { preloadDataMap } from "./readPreloadData";
import routeWrapperPlugin from "./routeWrapper";
import depthChangePlugin from "./depthChange";
import closeWhenRootPopPlugin from "./closeWhenRootPop";

declare const window: any;

const environment = karrotBridge?.driver.getCurrentEnvironment();
const theme = environment === "Android" ? "android" : "cupertino";

const activities = {
  Main: React.lazy(() => import("../activities/404")),
  CurrentRegion: React.lazy(() => import("../activities/404")),
  Poll: React.lazy(() => import("../activities/404")),
  Redirect: React.lazy(() => import("../activities/404")),
  PrePoll: React.lazy(() => import("../activities/404")),
  RegionSelect: React.lazy(() => import("../activities/404")),
  People: React.lazy(() => import("../activities/404")),
  404: React.lazy(() => import("../activities/404")),
};

const borderColor =
  theme === "cupertino"
    ? vars.$semantic.color.divider3
    : vars.$semantic.color.divider2;

export const { Stack, useFlow, useStepFlow, actions } = stackflow({
  transitionDuration: 350,
  activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor,
        textColor: vars.$scale.color.gray900,
        iconColor: vars.$scale.color.gray900,
      },
      dimBackgroundColor: vars.$semantic.color.paperDefault,
    }),
    historySyncPlugin({
      routes: {
        Main: "/",
        CurrentRegion: "/current-region",
        RegionSelect: "/regions/:regionId",
        Poll: "/regions/:regionId",
        Redirect: "/regions/:regionId/redirect",
        PrePoll: "/regions/:regionId/pre-poll",
        People: "/people/:partyId",
        404: "/404",
      },
      fallbackActivity: () => {
        captureMessage("존재하지 않는 activity에 접근했어요.", {
          level: Severity.Warning,
          extra: {
            url: window.location.href,
          },
        });

        return "404";
      },
    }),
    routeWrapperPlugin(),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: "general-election-2024",
    }),
    // preloadPlugin({
    //   loaders: {
    //     Main({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `Main#`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //     Redirect({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `Main#`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //     RegionSelect({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `RegionSelect#${JSON.stringify(activityParams)}`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //     CurrentRegion({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `Main#`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //     Poll({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `Poll#${JSON.stringify(activityParams)}`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //     PrePoll({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `Poll#${JSON.stringify(activityParams)}`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //     People({
    //       activityParams,
    //       isInitialActivity,
    //       initialContext,
    //       activityContext,
    //     }) {
    //       const key = `People#${JSON.stringify(activityParams)}`;

    //       if (isInitialActivity) {
    //         preloadDataMap[key] = {
    //           _t: "ok",
    //           data: initialContext.data,
    //         };
    //       }

    //       if (!preloadDataMap[key]) {
    //         const promise = window.___loader
    //           .loadPage((activityContext as any).path)
    //           .then((result: any) => {
    //             preloadDataMap[key] = {
    //               _t: "ok",
    //               data: result.json.data,
    //             };
    //           });

    //         preloadDataMap[key] = {
    //           _t: "pending",
    //           promise,
    //         };
    //       }

    //       return {
    //         key,
    //       };
    //     },
    //   },
    // }),
    depthChangePlugin(),
    closeWhenRootPopPlugin(),
  ],
});

export type TypeActivities = typeof activities;
