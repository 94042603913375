import { ErrorBoundary } from "@sentry/gatsby";
import type { StackflowReactPlugin } from "@stackflow/react";
import * as React from "react";

import ErrorBase from "~/components/ErrorBase";

import PageLayout from "../layouts/PageLayout";
import Loading from "~/components/Loading";

const routeWrapperPlugin = (): StackflowReactPlugin => () => {
  return {
    key: "hoc",
    wrapActivity({ activity }) {
      return (
        <PageLayout>
          <ErrorBoundary fallback={<ErrorBase />}>
            <React.Suspense fallback={<Loading />}>
              {activity.render()}
            </React.Suspense>
          </ErrorBoundary>
        </PageLayout>
      );
    },
  };
};

export default routeWrapperPlugin;
