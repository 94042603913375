export const MY_POLL_URL = "https://si.nec.go.kr/";
export const POLL_SITE = "https://www.nec.go.kr/site/avt/main.do";

/** @description TEST 용도 */
// poll: 361
// select: 617
// redirect:
// - to poll: 610
// - to select: 4496
// 구시군: 211
// 무투표: 396
export const DEFAULT_REGION_ID = 396;
export const KYOBO_TOWER = { lat: 37.5037, lng: 127.0241 };
export const DEFAULT_HUBO_IMAGE =
  "https://dnvefa72aowie.cloudfront.net/origin/etc/202404/989cecd528c002808b0748341235229083513c9345f0da19ffd677776c1fc793.png";

export const IS_DEV = process.env.NODE_ENV === "development";
/** @FIXME maybe it should be IS_ALPHA cuz rendering on Node? */
// export const IS_ALPHA = process.env.GATSBY_IS_ALPHA;
export const API_HOST = IS_DEV
  ? "https://alpha.daangn.com/api/v23/election"
  : "https://www.daangn.com/api/v23/election";
export const XPROPERTY_HOST = IS_DEV
  ? "https://xproperty.alpha.kr.karrotmarket.com/v1/countries/kr/properties"
  : "https://xproperty.kr.karrotmarket.com/v1/countries/kr/properties";

export const LOCAL_STORAGE_KEY = {
  lastRegionId: "last-region-id",
  lastParentRegionId: "last-parent-region-id",
};

export const POLL_DATES = {
  preStart: new Date(2024, 3, 5, 0, 0, 0),
  preSecondStart: new Date(2024, 3, 6, 6, 0, 0),
  preEnd: new Date(2024, 3, 6, 18, 0, 0),
  mainDay: new Date(2024, 3, 10, 0, 0, 0),
  mainStart: new Date(2024, 3, 10, 6, 0, 0),
  mainEnd: new Date(2024, 3, 10, 19, 30, 0),
  gaepyoStart: new Date(2024, 3, 10, 19, 30, 0),
  gaepyoEnd: new Date(2024, 3, 11, 9, 0, 0),
  endOfService: new Date(2024, 3, 11, 11, 0, 0),
};

export const JUNGDANG_COLOR = {
  더불어민주당: "#013B96",
  국민의힘: "#E61E2B",
  녹색정의당: "#007C36",
  새로운미래: "#1A6D80",
  개혁신당: "#ED6D00",
  자유통일당: "#E24A49",
  조국혁신당: "#06275E",
};
