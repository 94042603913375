import { vars } from "@seed-design/design-token";
import { AppScreen } from "@stackflow/plugin-basic-ui";
import type { PropOf } from "@stackflow/plugin-basic-ui/dist/utils";
import { useActivity } from "@stackflow/react";

import React, { createContext, useContext, useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import { changeRouteStyle } from "~/bridge/changeRouteStyle";
import { useFlow } from "~/stackflow";

type AppScreenProps = PropOf<typeof AppScreen>;
type AppScreenOptions = Omit<AppScreenProps, "children">;
export type AppBarProps = AppScreenProps["appBar"];

type PageContextType = {
  screenOptions?: AppScreenOptions;
  setScreenOptions: React.Dispatch<
    React.SetStateAction<AppScreenOptions | undefined>
  >;
};
const PageContext = createContext<PageContextType>({
  screenOptions: undefined,
  setScreenOptions: () => undefined,
});
export const usePageContext = () => useContext(PageContext);

const PageLayout = (props: AppScreenProps) => {
  const [screenOptions, setScreenOptions] = useState<
    AppScreenOptions | undefined
  >();
  const { pop } = useFlow();
  const { id, isRoot, isActive } = useActivity();

  useEffect(() => {
    if (!isActive) return;
    if (!isRoot) return;
    // Root 인데 preventSwipeBack 옵션이 있는 경우
    if (screenOptions?.preventSwipeBack !== undefined) {
      changeRouteStyle({ backSwipable: !screenOptions.preventSwipeBack });
    }
  }, [screenOptions?.preventSwipeBack, isActive]);

  return (
    <RecoilRoot>
      <PageContext.Provider value={{ screenOptions, setScreenOptions }}>
        <AppScreen
          backgroundColor={vars.$semantic.color.paperDefault}
          dimBackgroundColor={vars.$static.color.staticBlackAlpha500}
          {...props}
          {...screenOptions}
          appBar={{
            iconColor: vars.$scale.color.gray900,
            textColor: vars.$scale.color.gray900,
            border: false,
            borderSize: "0.5px",
            borderColor: vars.$semantic.color.divider3,
            closeButton: {
              onClick: () => pop(),
            },
            ...props.appBar,
            ...screenOptions?.appBar,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {props.children}
          </div>
        </AppScreen>
      </PageContext.Provider>
    </RecoilRoot>
  );
};

export default PageLayout;
