import {
  installKarrotBridgeDriver,
  makeKarrotBridge,
} from "@daangn/karrotbridge";

export const karrotBridge = (() => {
  if (typeof window === "undefined") {
    return null;
  }

  const { driver } = installKarrotBridgeDriver();

  return makeKarrotBridge({
    driver,
  });
})();

const bridgeEnvironment = karrotBridge?.driver.getCurrentEnvironment();
export const isWeb = bridgeEnvironment === "Web";
